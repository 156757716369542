<template>
    <div class="logout-wrapper">
      <div>
        <h1 class="card-title fw-bold mb-1 nexos-main-title">
          {{ $t('auth.logout.title') }}
        </h1>
        <p class="card-text mb-2">
          {{ $t('auth.logout.subtitle') }}
        </p>
      </div>

      <b-alert
        v-if="error"
        variant="danger"
        show
        class="text-center w-100 rounded pb-2 pt-2"
      >
        {{ $t(error) }}
      </b-alert>

      <b-form class="auth-logout-form mt-2" @submit.prevent="handleSubmit">
        <!-- Submit Button -->
        <div class="auth-btn-container">
          <b-button type="submit" class="auth-btn auth-btn-primary">
            {{ $t('auth.logout.submit') }}
          </b-button>
        </div>
      </b-form>

      <!-- Cancel -->
      <p class="text-center mt-2">
        <b-link :to="{ name: 'Resumen' }">
          {{ $t('common.cancel') }}
        </b-link>
      </p>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'Login',

  mixins: [],

  data() {
    return {
      
      error: null
    }
  },

  computed: {
    
  },

  methods: {
    async handleSubmit() {
      console.log('logout...');
      this.$store.dispatch('logout');

      // await router.replace({ 
      //   name: 'auth-login', 
      //   params: { 
      //     communityId: communitySlug 
      //   } 
      // })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
